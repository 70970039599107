import { NgModule } from '@angular/core';
import { RouterGuardsViewModel } from '../ui/models/RouterGuards.view-model';
import { PageContentTitleComponent } from '../ui/components/titles/page-content-title/page-content-title.component';
import { AsyncActionProcessingComponent } from '../ui/components/async-action-processing/async-action-processing.component';
import { StubTitleComponent } from '../ui/components/stubs/parts/stub-title/stub-title.component';
import { StubTextsComponent } from '../ui/components/stubs/parts/stub-texts/stub-texts.component';
import { WidgetTitleComponent } from '../ui/components/titles/widget-title/widget-title.component';
import { FlowSeparatorComponent } from '../ui/components/separators/flow-separator/flow-separator.component';
import { StretchedBlockComponent } from '../ui/components/stretched-block/stretched-block.component';
import { CommonStubComponent } from '../ui/components/stubs/common-stub/common-stub.component';
import { ListSectionSeparatorComponent } from '../ui/components/separators/list-section-separator/list-section-separator.component';
import { TriangleIconComponent } from '../ui/components/icons/triangle-icon/triangle-icon.component';
import { CrossLargeColorizedComponent } from '../ui/components/icons/cross-large-colorized/cross-large-colorized.component';
import { MessageLargeColorizedComponent } from '../ui/components/icons/message-large-colorized/message-large-colorized.component';
import {
  JugUICommonModule,
  EmailInputMask,
  JugPortalsModule,
} from '@ui/jug-ui';
import { PageLayoutComponent } from '../ui/layouts/auth-pages-layout/page-layout.component';
import { UnpackErrorCodePipe } from '../ui/components/errors/unpack-error-code.pipe';
import { EventTicketIconComponent } from '../ui/components/icons/event-card-icons/event-ticket-icon/event-ticket-icon.component';
import { InplaceTitleComponent } from '../ui/components/titles/inplace-title/inplace-title.component';
import { InplaceLittleDescriptionBlockComponent } from '../ui/components/descriptions/inplace-little-description-block/inplace-little-description-block.component';
import { TableTitleComponent } from '../ui/components/titles/table-title/table-title.component';
import { InfoBlockHeaderComponent } from '../ui/components/info-block/info-block-header/info-block-header.component';
import { InfoBlockFooterComponent } from '../ui/components/info-block/info-block-footer/info-block-footer.component';
import { InfoBlockTitleComponent } from '../ui/components/info-block/info-block-title/info-block-title.component';
import { InfoBlockDescriptionComponent } from '../ui/components/info-block/info-block-description/info-block-description.component';
import { InfoBlockComponent } from '../ui/components/info-block/info-block/info-block.component';
import { AcceptanceTicketErrorComponent } from '../ui/components/tickets/acceptance-ticket-error/acceptance-ticket-error.component';
import { AcceptanceTicketComponent } from '../ui/components/tickets/acceptance-ticket/acceptance-ticket.component';
import { AcceptanceTicketConferenceComponent } from '../ui/components/tickets/acceptance-ticket/acceptance-ticket-conference/acceptance-ticket-conference.component';
import { AcceptanceTicketInviteComponent } from '../ui/components/tickets/acceptance-ticket/acceptance-ticket-invite/acceptance-ticket-invite.component';
import { CutoutComponent } from '../ui/components/decorations/cutout/cutout.component';
import { StampOverComponent } from '../ui/components/stylizations/stamp/stamp-over/stamp-over.component';
import { StampContainerComponent } from '../ui/components/stylizations/stamp/stamp-container/stamp-container.component';
import { NavBreadcrumbsComponent } from '../ui/components/navigation/nav-breadcrumbs/nav-breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { PageNavigationComponent } from '../ui/components/navigation/page-navigation/page-navigation.component';
import { NavigationPanelComponent } from '../ui/components/navigation/page-navigation/navigation-panel/navigation-panel.component';
import { OrderPageNavigationDataComponent } from '../ui/components/orders/order-page-navigation/order-page-navigation-data/order-page-navigation-data.component';
import { NavTabsComponent } from '../ui/components/tabs/nav-tabs/nav-tabs.component';
import { CompanyOrderEventImageComponent } from '../ui/components/company-orders/company-order-event-image/company-order-event-image.component';
import { DistributionTicketsMessageComponent } from '../ui/components/tickets/distribution-tickets-message/distribution-tickets-message.component';
import { PaginatorComponent } from '../ui/components/paginator/paginator.component';
import { StartLeftArrowIconComponent } from '../ui/components/icons/start-left-arrow-icon/start-left-arrow-icon.component';
import { LeftArrowIconComponent } from '../ui/components/icons/left-arrow-icon/left-arrow-icon.component';
import { RightArrowIconComponent } from '../ui/components/icons/right-arrow-icon/right-arrow-icon.component';
import { EndRightArrowIconComponent } from '../ui/components/icons/end-right-arrow-icon/end-right-arrow-icon.component';
import { MailLargeColorizedComponent } from '../ui/components/icons/mail-large-colorized/mail-large-colorized.component';
import { MailsLargeColorizedComponent } from '../ui/components/icons/mails-large-colorized/mails-large-colorized.component';


@NgModule({
  declarations: [
    CommonStubComponent,

    PageContentTitleComponent,
    StubTitleComponent,

    StubTextsComponent,
    WidgetTitleComponent,

    AsyncActionProcessingComponent,

    FlowSeparatorComponent,
    StretchedBlockComponent,

    ListSectionSeparatorComponent,

    PageLayoutComponent,

    TriangleIconComponent,

    UnpackErrorCodePipe,

    EventTicketIconComponent,
    MessageLargeColorizedComponent,
    CrossLargeColorizedComponent,
    MailLargeColorizedComponent,
    MailsLargeColorizedComponent,

    InplaceTitleComponent,
    InplaceLittleDescriptionBlockComponent,

    TableTitleComponent,

    InfoBlockHeaderComponent,
    InfoBlockTitleComponent,
    InfoBlockDescriptionComponent,
    InfoBlockComponent,
    InfoBlockFooterComponent,

    AcceptanceTicketErrorComponent,
    StampOverComponent,
    StampContainerComponent,

    AcceptanceTicketComponent,
    AcceptanceTicketConferenceComponent,
    AcceptanceTicketInviteComponent,
    CutoutComponent,

    PageNavigationComponent,
    NavigationPanelComponent,
    OrderPageNavigationDataComponent,
    NavTabsComponent,
    CompanyOrderEventImageComponent,

    PaginatorComponent,

    NavBreadcrumbsComponent,

    DistributionTicketsMessageComponent,

    StartLeftArrowIconComponent,
    LeftArrowIconComponent,
    RightArrowIconComponent,
    EndRightArrowIconComponent,
  ],
  exports: [
    RouterModule,
    JugUICommonModule,
    JugPortalsModule,

    CommonStubComponent,

    PageContentTitleComponent,
    StubTitleComponent,

    StubTextsComponent,
    WidgetTitleComponent,

    AsyncActionProcessingComponent,

    FlowSeparatorComponent,
    StretchedBlockComponent,

    ListSectionSeparatorComponent,

    PageLayoutComponent,

    TriangleIconComponent,

    UnpackErrorCodePipe,

    EventTicketIconComponent,
    MessageLargeColorizedComponent,
    CrossLargeColorizedComponent,
    MailLargeColorizedComponent,
    MailsLargeColorizedComponent,

    InplaceTitleComponent,
    InplaceLittleDescriptionBlockComponent,

    TableTitleComponent,

    InfoBlockHeaderComponent,
    InfoBlockTitleComponent,
    InfoBlockDescriptionComponent,
    InfoBlockComponent,
    InfoBlockFooterComponent,

    AcceptanceTicketErrorComponent,
    StampOverComponent,
    StampContainerComponent,

    AcceptanceTicketComponent,
    AcceptanceTicketConferenceComponent,
    AcceptanceTicketInviteComponent,
    CutoutComponent,

    PageNavigationComponent,
    NavigationPanelComponent,
    OrderPageNavigationDataComponent,
    NavTabsComponent,
    CompanyOrderEventImageComponent,

    PaginatorComponent,

    NavBreadcrumbsComponent,

    DistributionTicketsMessageComponent,

    StartLeftArrowIconComponent,
    LeftArrowIconComponent,
    RightArrowIconComponent,
    EndRightArrowIconComponent,
  ],
  imports: [
    RouterModule,
    JugUICommonModule,
    JugPortalsModule,
  ],
  providers: [
    RouterGuardsViewModel,
    EmailInputMask,
  ],
})
export class SharedModule {

}
